<template>
  <div :class="customClass">
    <div class="app-content">
      <PDFViewer
        ref="pdfRef"
        :source="pdfFile"
        @download="downloadPdf"
        :controls="controls"
        :filename="filename"
      />
    </div>
  </div>
</template>

<script>
export const DOWNLOAD = "download";
export const PRINT = "print";
export const DOUBLE = "double";
export const FULLSCREEN = "fullscreen";
export const ABOUT = "about";
export const FULLPAGE = "fullpage";
export const ROTATE = "rotate";
export const ZOOM = "zoom";
export const CATALOG = "catalog";
export const SWITCH_PAGE = "switchPage";

export const pdfControls = [
  DOWNLOAD,
  PRINT,
  DOUBLE,
  FULLSCREEN,
  ABOUT,
  FULLPAGE,
  ROTATE,
  ZOOM,
  CATALOG,
  SWITCH_PAGE,
];

import PDFViewer from "../modules/vue-pdf-viewer/vue2-pdf-viewer";
import { v4 as uuidv4 } from "uuid";

export default {
  props: {
    pdfFile: {
      require: true,
    },
    customClass: {
      type: String,
      default: () => null,
    },
    showClose: {
      type: Boolean,
      default: () => true,
    },
    controls: {
      type: Array,
      default: () => {
        return pdfControls;
      },
    },
    filename: {
      type: String,
      default: () => null,
    },
  },
  components: {
    PDFViewer,
  },
  data() {
    return {
      loading: false,
    };
  },

  methods: {
    downloadPdf(data) {
      const { filename, src } = data;

      var binary = Buffer.from(
        src.replace(/^data:[\w/+.-]+;base64,/, ""),
        "base64"
      ).toString("binary");
      var len = binary.length;
      var buffer = new ArrayBuffer(len);
      var view = new Uint8Array(buffer);
      for (var i = 0; i < len; i++) {
        view[i] = binary.charCodeAt(i);
      }

      const url = URL.createObjectURL(
        new Blob([view], {
          type: "application/pdf",
        })
      );
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = filename ?? `${uuidv4()}.pdf`;
      anchor.style.display = "none";
      document.body.append(anchor);
      anchor.click();
      setTimeout(() => {
        URL.revokeObjectURL(url);
        document.body.removeChild(anchor);
      }, 1000);
    },
  },

  mounted() {},
};
</script>
<style lang="scss">
.vue-pdf-embed > div {
  margin-bottom: 8px;
  margin-top: 8px;
  display: flex;
  justify-content: center;
}

.app-header {
  padding: 16px;
  box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
  background-color: #555;
  color: #ddd;
}

.app-content {
  overflow: auto;
  height: calc(100vh - 62px);
  background-color: #333;
}
</style>
